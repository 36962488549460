"use client";
import { useEffect } from "react";
import Footer from "@/components/Footer/Footer";
import NavBar from "@/components/NavBar/NavBar";
import Button from "@/components/Button/Button";
import Logging from "@/utils/Logging";
import mixpanel from "mixpanel-browser";

import styles from "./error.module.scss";

export const runtime = "edge";

export default function Error({ error, reset }) {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_KEY || "", {
    debug: true,
    track_pageview: true,
    persistence: "localStorage",
    loaded: () => {
      mixpanel.track("Mixpanel successfully initialized");
    },
  });

  useEffect(() => {
    try {
      Logging.networkError("NextJS Errors - Global Error Boundary", {
        pageName: "Error Boundary",
        errorMessage: error.message,
        errorCode: error.code,
        statusCode: error.response?.status,
      });
    } catch (err) {
      console.log({ err });
    }
  }, [error]);

  return (
    <div>
      <NavBar />
      <div className={styles.wrapper}>
        <div className={styles.wrapper}>
          <div className={styles.content}>
            <h1>Looks like an error occured</h1>

            <p>
              Try resetting the page. If the problem persists, contact support
              for assistance.
            </p>

            <div className={styles.buttonsWrapper}>
              <Button variant="secondary" onClick={reset}>
                Refresh
              </Button>

              <Button
                variant="secondary"
                onClick={() => {
                  window.open("mailto:info@spawning.ai", "noopener noreferrer");
                }}
              >
                Contact us
              </Button>
            </div>

            <Button
              onClick={() => {
                window.open("/");
              }}
            >
              Go back to the home page
            </Button>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}
