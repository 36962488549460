import React from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import styles from "./Footer.module.scss";
import { IconButton, Tooltip } from "@mui/material";
import Link from "next/link";
import TwitterIcon from "../../../public/assets/icons/TwitterIcon";

const Footer = () => {
  return (
    <div className={styles.footerWrapper}>
      <div className={styles.footerContent}>
        <div className={styles.internalLinksWrapper}>
          <Link href="/about">About</Link>
          <a
            href="https://api.spawning.ai/spawning-api"
            rel="noopener noreferrer"
            target="_blank"
          >
            API
          </a>
          <Link href="/faq">FAQ</Link>
          <Link href="/privacy">Privacy</Link>
          <Link href="/terms-of-service">Terms of Service</Link>
        </div>

        <div className={styles.externalLinksWrapper}>
          <Tooltip title="info@spawning.ai">
            <IconButton
              href="mailto:info@spawning.ai"
              rel="noopener noreferrer"
              target="_blank"
              sx={{
                color: "#000",
              }}
            >
              <MailOutlineIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="spawning.substack.com">
            <IconButton
              href="https://spawning.substack.com/"
              rel="noopener noreferrer"
              target="_blank"
              sx={{
                color: "#000",
              }}
            >
              <NewspaperIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title="twitter.com/spawning_">
            <IconButton
              href="https://twitter.com/spawning_"
              rel="noopener noreferrer"
              target="_blank"
            >
              <TwitterIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default Footer;
