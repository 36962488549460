import React from "react";

const TwitterIcon = () => (
  <svg
    width="19px"
    height="19px"
    viewBox="0 0 431.20337 389.76843"
    version="1.1"
    id="svg5"
    xmlSpace="preserve"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs id="defs2" />
    <g id="layer1" transform="translate(227.98816,51.751688)">
      <path
        d="m 111.60976,-51.751688 h 66.12063 L 33.276384,113.35018 203.21522,338.01675 H 70.154418 L -34.0636,201.75742 -153.31262,338.01675 h -66.16064 L -64.965148,161.42136 -227.98816,-51.751688 H -91.549341 L 2.6545805,72.7943 Z M 88.403591,298.43991 H 125.04177 L -111.45749,-14.253875 h -39.31665 z"
        id="path2501"
      />
    </g>
  </svg>
);

export default TwitterIcon;
